@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-ExtraLight.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Light.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Bold.ttf) format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-ExtraBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: local('Montserrat'), url(./assets/fonts/Montserrat-ExtraBold.ttf) format('opentype');
}